<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-11 16:50:34
 * @LastEditors: hutian
 * @LastEditTime: 2021-04-16 09:37:26
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" :maskClosable="false" @ok="toSubmit" okText="提交">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="选择事务所" >
                <a-select placeholder="请选择事务所" v-model="rowData.firmId">
                    <a-select-option :value="item.id" v-for="(item, index) in templateList" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="上级部门" prop="parentId">
                <a-tree-select
                    v-model="rowData.parentId"
                    :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                    :treeData="depts"
                    placeholder="上级部门"
                    treeDefaultExpandAll>
                    </a-tree-select>
            </a-form-model-item>
            <a-form-model-item label="部门名称" prop="name">
                <a-input v-model="rowData.name" placeholder="部门名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否启用" prop="flagEnable">
                <a-radio-group v-model="rowData.flagEnable">
                    <a-radio :value="true">正常</a-radio>
                    <a-radio :value="false">停用</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="备注">
                <a-textarea v-model="rowData.remark" placeholder="备注"></a-textarea>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editFirmDepartment, selectByIdFirmDepartment, addFirmDepartment } from '../api/FirmDepartmentApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                name: [
                    { required: true, message: '请输入部门名称', trigger: 'blur' }
                ],
                flagEnable: [
                    { required: true, message: '请输入是否启用', trigger: 'blur' }
                ],
                parentId: [
                    { required: true, message: '请选择上级部门', trigger: 'change' }
                ]
            },
            depts: [{ key: '0', value: '0', title: '无' }],
            templateList: []
        }
    },
    methods: {
        // 获取行数据
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = {}
            if(handle === 'add') {
                this.$set(this.rowData, 'parentId', 0)
                this.$set(this.rowData, 'flagEnable', true)
            }
            if(handle === 'edit') {
                selectByIdFirmDepartment(row.id).then(res => {
                    this.rowData = res.body
                })
            }
            this.depts = [{ key: '0', value: '0', title: '无' }]
            this.getParentList()
        },
        // 获取上级部门
        getParentList() {
            this.axios.get('/api/firm/mhiac/mhiacFirm/list?pageSize=9999').then(res => {
                this.templateList = res.body.records
            })
            this.axios.get('/api/firm/firm/firmDepartment/list?pageSize=9999&sourceType=1').then(res => {
                this.buildtree(res.body, this.depts, 0)
            })
        },
        // 对上级部门进行组装成树结构
        buildtree (list, arr, parentId) {
            list.forEach(item => {
                if (item.parentId === parentId) {
                var child = {
                    key: item.id,
                    value: item.id + '',
                    title: item.name,
                    children: []
                }
                this.buildtree(list, child.children, item.id)
                arr.push(child)
                }
            })
        },
        // 表单提交
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                this.rowData.sourceType = 1
                const res = this.handle === 'add' ? await addFirmDepartment(this.rowData) : await editFirmDepartment(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
